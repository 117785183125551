import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getButtonStyles, buttonStyleOptions, themeOptions } from '../../assets/linktree-themes';

const defaultTheme = themeOptions[0];

const SimpleLandingStyle = ({ children, isPreview, data, className }) => {
  const { fontFamily, background, buttonStyles } = data;

  const linksState = data.links ? JSON.parse(data.links) : [];
  const { backgroundStyle } = background ? JSON.parse(background) : defaultTheme.background;
  const { fontName, fontColor } = fontFamily ? JSON.parse(fontFamily) : defaultTheme.fontFamily;
  const { buttonStyleId, buttonFontColor, buttonColor } = buttonStyles
    ? JSON.parse(buttonStyles)
    : defaultTheme.buttonStyles;

  const globalCssButton = getButtonStyles().find(({ id }) => id === buttonStyleId)?.cssButton;
  const getGlobalButtonStyle = buttonStyleOptions.find(({ type }) =>
    buttonStyleId.includes(type.split(' ').join('').toLocaleLowerCase()),
  )?.getButtonStyle;

  const fullScreen = `
    width: ${isPreview ? '100%' : '100vw'};
    height: auto;
    min-height: ${isPreview ? '100%' : '100vh'};
  `;

  const StyledWrapper = styled.div`
    position: relative;
    overflow: hidden;
    ${fullScreen}

    /* DEFINE YOUR OWN GLOBAL STYLES HERE */
    font-family: '${fontName}', sans-serif !important;
    color: ${fontColor};
    ${backgroundStyle}
    /* ============ */

    .linktree__container {
      display: flex;
      margin: auto;
      max-width: 550px;
      width: 100%;

      .linktree__wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 12px;
        width: 100%;

        /* Header Styles */
        .linktree__header {
          display: flex;
          flex-direction: column;
          align-items: center;

          .linktree__merchant--logo {
            width: 96px;
            height: 96px;
            margin: 10px 0;
            border-radius: 50%;
          }

          .linktree__title {
            max-width: 100%;
            margin: 5px 0 10px 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .linktree__profile {
            margin-bottom: 10px !important;
            font-size: 14px;
            line-height: 1.5;
            text-align: center;
            word-break: break-word;
          }
        }

        /* Main Styles */
        .linktree__main--content {
          display: flex;
          flex-direction: column;
          gap: 0;
          padding: 0;

          /* Link Buttons */
          .linktree__links {
            display: flex;
            flex-direction: column;

            /* Link Button */
            .linktree__links--item {
              $self: &;
              z-index: 1;

              /* Iframe Youtube */
              &--iframe {
                display: flex;
                overflow: hidden;
                border: 2px solid rgba(255, 255, 255, 0);
                border-radius: 15px;
              }

              &--iframe--tiktok iframe {
                border-radius: 15px;
              }

              a {
                position: relative;
                cursor: pointer;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-box-align: center;
                -webkit-box-pack: center;
                vertical-align: middle;
                box-sizing: border-box;
              }

              /* Image Banner*/
              .linktree__image--banner {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 2px;
                height: 200px;

                @media screen and (min-width: 425px) {
                  height: 280px;
                }

                @media screen and (min-width: 576px) {
                  height: 320px;
                }
              }

              .linktree__social--links a {
                width: fit-content;
              }

              /* Button Style */
              .linktree__link--button {
                padding: 15px 20px;

                * {
                  margin: 0;
                }

                ${linksState.map((link) => {
                  const currentButtonStyleId = link?.buttonStyleId || buttonStyleId;
                  const cssButton = getButtonStyles().find(({ id }) => id === currentButtonStyleId)
                    ?.cssButton;
                  const { getButtonStyle } = buttonStyleOptions.find(({ type }) =>
                    currentButtonStyleId.includes(type.split(' ').join('').toLocaleLowerCase()),
                  );
                  return `
                    &--${link?.id} {
                      background-color: ${link?.buttonColor || buttonColor};
                      border: 0 solid ${link?.buttonColor || buttonColor};
                      color: ${link?.buttonFontColor || buttonFontColor};

                      ${getButtonStyle(
                        link?.buttonColor || buttonColor,
                        link?.buttonFontColor || buttonFontColor,
                        cssButton,
                      )}
                    }
                  `;
                })}
              }

              .linktree__link--card {
                padding: 0px;
                border-radius: 10px;

                /* DEFINE YOUR OWN CARD STYLES HERE */
                background-color: ${buttonColor};
                border: 0 solid ${buttonColor};
                color: ${buttonFontColor};

                input {
                  border-radius: 5px;
                }

                .linktree__card--button {
                  /* DEFINE YOUR OWN CARD STYLES HERE */
                  background-color: ${buttonFontColor};
                  border: 0 solid ${buttonFontColor};
                  color: ${buttonColor};

                  ${getGlobalButtonStyle(buttonFontColor, buttonColor, globalCssButton)}

                  word-break: normal;
                }

                &--dashed {
                  padding: 0px;
                  border-radius: 10px;

                  /* DEFINE YOUR OWN CARD STYLES HERE */
                  background-color: ${buttonColor};
                  border: 2px dashed ${buttonFontColor};
                  color: ${buttonFontColor};

                  .divider {
                    border-bottom: 2px dashed ${buttonFontColor};
                  }

                  .linktree__card--button {
                    background: none;
                    width: 100%;
                    border: none;
                    text-align: left;
                  }

                  .linktree__card--button:hover {
                    /* DEFINE YOUR OWN CARD STYLES HERE */
                    background-color: ${buttonFontColor};
                    border: 2px dashed ${buttonFontColor};
                    color: ${buttonColor};

                    .divider {
                      border-bottom: 2px dashed ${buttonColor};
                    }
                  }
                }
              }

              /* Iframe Youtube */
              iframe.youtube {
                width: 100%;
                height: 200px ${isPreview ? '!important' : ''};

                @media screen and (min-width: 425px) {
                  height: 300px;
                }
              }

              /* Twitter Tweet */
              .twitter-tweet {
                margin: auto !important;

                iframe {
                  width: 100% !important;
                }
              }

              .support_button {
                /* DEFINE YOUR OWN SUPPORT BUTTON STYLES HERE */
                background-color: ${buttonFontColor};
                border: 0 solid ${buttonFontColor};
                color: ${buttonColor};

                ${getGlobalButtonStyle(buttonFontColor, buttonColor, globalCssButton)}
                /* ============ */

                * {
                  margin: 0;
                }

                &.disabled {
                  opacity: 1 !important;
                }
              }

              .support_card {
                /* DEFINE YOUR OWN SUPPORT CARD STYLES HERE */
                background-color: ${buttonColor};
                border: 1px solid ${buttonFontColor};
                color: ${buttonFontColor};

                input,
                textarea {
                  border: 1px solid ${buttonFontColor};
                }
              }

              &--divider {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                &--hr {
                  display: block;
                  width: 100%;
                  height: 1px;
                  border-top-width: 1px;
                  border-top-color: inherit;
                  margin: 1em 0;
                  padding: 0;
                }
                &--hr.dashed {
                  border-top-style: dashed;
                }
              }

              .btn-light,
              .list-group-item span {
                color: #212529 !important;
              }

              * {
                overflow-wrap: break-word;
                word-break: break-word;
                font-weight: 600;
              }

              .tick {
                color: initial;
              }

              .form-container,
              .faq-container {
                display: flex;
                flex-direction: column;

                h2 {
                  font-size: 32px;
                  text-align: center;
                  margin-bottom: 12px;
                }

                p {
                  font-size: 16px;
                  text-align: center;
                  margin-bottom: 16px;
                }

                button {
                  margin: 25px auto 0;
                  width: fit-content;
                }
              }

              .faq-container {
                .faq-content {
                  padding: 0 20px;
                  border-radius: 10px;
                }

                h2 {
                  margin-bottom: 25px;
                }

                button {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                }

                h3,
                p {
                  margin: 0;
                }

                .faq-question {
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  padding: 15px 0;
                }

                .faq-answer {
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  padding: 15px 0;
                }

                .faq-answer.last {
                  border-bottom: 0;
                }
              }

              ${linksState.map((link) => {
                const paddingTop = link?.paddingTop || 0;
                const paddingBottom = link?.paddingBottom || 0;
                const paddingLeft = link?.paddingLeft || 0;
                const paddingRight = link?.paddingRight || 0;
                const currentButtonStyleId = link?.buttonStyleId || buttonStyleId;
                const cssButton = getButtonStyles().find(({ id }) => id === currentButtonStyleId)
                  ?.cssButton;
                const { getButtonStyle } = buttonStyleOptions.find(({ type }) =>
                  currentButtonStyleId.includes(type.split(' ').join('').toLocaleLowerCase()),
                );
                return `
                &--${link?.id} {
                  ${link?.backgroundColor ? `background-color: ${link?.backgroundColor};` : ''}
                  padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px;


                  .form-container, .faq-container {
                    .faq-content {
                      background-color: ${link?.buttonColor || buttonColor};

                      button {
                        background-color: ${link?.buttonColor || buttonColor};
                        border: 0 solid ${link?.buttonColor || buttonColor};
                        color: ${link?.buttonFontColor || buttonFontColor};
                      }
                    }

                    .faq-answer {
                      color: ${link?.buttonFontColor || buttonFontColor}E6;
                    }

                    .faq-answer {
                      border-top: 1px solid ${link?.buttonFontColor || buttonFontColor}4D;
                      border-bottom: 1px solid ${link?.buttonFontColor || buttonFontColor}4D;
                    }
                  }

                  .form-container button {
                    background-color: ${link?.buttonColor || buttonColor};
                    border: 0 solid ${link?.buttonColor || buttonColor};
                    color: ${link?.buttonFontColor || buttonFontColor};

                    ${getButtonStyle(
                      link?.buttonColor || buttonColor,
                      link?.buttonFontColor || buttonFontColor,
                      cssButton,
                    )}
                  }
                }
                  `;
              })}
            }
          }

          /* Social Link Icons */
          .linktree__social--links {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 16px;
          }
        }
      }
    }

    /* Footer Styles */
    .linktree__footer {
      background: none !important;
      margin-top: auto;
      z-index: 9999;
      margin-bottom: 20px;
      .linktree__mayar--copyright {
        display: flex;
        justify-content: center;

        /* Mayar Copyright */
        a {
          border: solid 1px rgb(220, 220, 220);
          border-radius: 10px;
          padding: 3px 15px;
          color: inherit !important;
          font-family: 'Open Sans', sans-serif;

          .linktree__mayar--logo {
            margin-left: 5px;
            height: 24px;
          }
        }
      }
    }

    .linktree__modal {
      max-width: 800px !important;
    }

    * {
      font-family: inherit;
      color: inherit;
    }

    .font-title-product {
      font-size: 12px;
      margin-bottom: -20px;
      text-wrap: balance;
      @media screen and (min-width: 500px) {
        font-size: 16px;
      }
    }
    .card-product-bio {
      padding: 10px !important;
      @media screen and (min-width: 500px) {
        padding: 20px !important;
      }
    }
    .btn-buy-bio {
      margin-top: 5px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
    }
    .grid-products {
      display: grid;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr); /* Three columns by default */
      grid-column-gap: 20px;
      grid-row-gap: 15px;
      /* Media query for screens below 992px */
      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr); /* Two columns for screens below 992px */
        grid-column-gap: 8px;
        grid-row-gap: 13px;
      }
    }
    .max-width-category {
      max-width: 940px;
      width: 90%;
    }

    .invalid-feedback {
      color: #f597a2 !important;
    }

    input {
      color: #202020;
    }
  `;

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) return React.cloneElement(child);
    return child;
  });

  return <StyledWrapper className={className}>{childrenWithProps}</StyledWrapper>;
};

SimpleLandingStyle.defaultProps = {
  data: {},
  isPreview: false,
  className: '',
};

SimpleLandingStyle.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
  className: PropTypes.string,
};

export default SimpleLandingStyle;
