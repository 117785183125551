import './styles.scss';
import React from 'react';

export const backgroundAnimationList = ['Gradient', 'Parallax Star'];

const BackgroundAnimation = ({ id }) => {
  switch (id) {
    case 'Parallax Star':
      return (
        <div className="background--animation parallax-star-background-animation">
          <div id="stars" />
          <div id="stars2" />
          <div id="stars3" />
        </div>
      );
    case 'Gradient':
      return <div className="background--animation gradient-background-animation" />;
    default:
      return null;
  }
};

export default BackgroundAnimation;
