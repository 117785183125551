export const fontOptions = [
  {
    type: 'Modern',
    fonts: [
      'Open Sans',
      'Barlow',
      'Capriola',
      'DM Sans',
      'Gothic A1',
      'IBM Plex Sans',
      'Inter',
      'Karla',
      'Lato',
      'Montserrat',
      'Oswald',
      'Poppins',
      'Quicksand',
      'Roboto',
      'Rubik',
      'Work Sans',
    ],
  },
  {
    type: 'Klasik',
    fonts: [
      'Arvo',
      'Bitter',
      'Calistoga',
      'Crimson Text',
      'EB Garamond',
      'IBM Plex Serif',
      'Libre Baskerville',
      'Lora',
      'Merriweather',
      'Noto Serif',
      'Playfair Display',
      'PT Serif',
      'Roboto Slab',
      'Source Serif Pro',
      'Zilla Slab',
    ],
  },
  {
    type: 'Unik',
    fonts: [
      'Bebas Neue',
      'Courgette',
      'Fredoka One',
      'Hepta Slab',
      'Kite One',
      'Nanum Pen Script',
      'Nova Round',
      'Pacifico',
      'Rock Salt',
      'Space Mono',
      'Special Elite',
    ],
  },
];

export const buttonStyleOptions = [
  {
    type: 'Fill',
    getButtonStyle: (buttonColor, buttonFontColor, cssButton) => `
      ${cssButton}
      border: 2px solid transparent;
      background-color: ${buttonColor};
      color: ${buttonFontColor};

      &:hover {
        border: 2px solid ${buttonColor};
        color: ${buttonColor};
        background-color: ${buttonFontColor};
      }
    `,
    styles: [
      {
        id: 'fill',
        cssButton: `
          border-radius: 0;
        `,
      },
      {
        id: 'fillrounded',
        cssButton: `
          border-radius: 0.5rem;
      `,
      },
      {
        id: 'fillcircular',
        cssButton: `
          border-radius: 6.25rem;
      `,
      },
    ],
  },
  {
    type: 'Outline',
    getButtonStyle: (buttonColor, buttonFontColor, cssButton) => `
      ${cssButton}
      border: 2px solid ${buttonColor};
      color: ${buttonColor};

      &:hover {
        border: 2px solid transparent;
        background-color: ${buttonColor};
        color: ${buttonFontColor};
      }
    `,
    styles: [
      {
        id: 'outline',
        cssButton: `
          border: 2px solid;
          border-radius: 0;
          background: none;
        `,
      },
      {
        id: 'outlinerounded',
        cssButton: `
          border: 2px solid;
          border-radius: 0.5rem;
          background: none;
        `,
      },
      {
        id: 'outlinecircular',
        cssButton: `
          border: 2px solid;
          border-radius: 6.25rem;
          background: none;
        `,
      },
    ],
  },
  {
    type: 'Hard shadow',
    getButtonStyle: (buttonColor, buttonFontColor, cssButton) => `
      ${cssButton}
      border: 2px solid ${buttonColor};
      color: ${buttonColor};

      &:hover {
        background-color: ${buttonColor};
        border-color: ${buttonFontColor};
        color: ${buttonFontColor};
      }
    `,
    styles: [
      {
        id: 'hardshadow',
        cssButton: `
          border: 2px solid;
          border-radius: 0;
          box-shadow: 0.25rem 0.25rem 0 0;
          background: none;
        `,
      },
      {
        id: 'hardshadowrounded',
        cssButton: `
          border: 2px solid;
          border-radius: 0.5rem;
          box-shadow: 0.25rem 0.25rem 0 0;
          background: none;
        `,
      },
      {
        id: 'hardshadowcircular',
        cssButton: `
          border: 2px solid;
          border-radius: 6.25rem;
          box-shadow: 0.25rem 0.25rem 0 0;
          background: none;
        `,
      },
    ],
  },
  {
    type: 'Soft shadow',
    getButtonStyle: (buttonColor, buttonFontColor, cssButton) => `
      ${cssButton}
      background-color: ${buttonColor};
      color: ${buttonFontColor};
      transition: transform 0.3s, box-shadow 0.1s;

      &:hover {
        transform: scale(1.01, 1.01);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      }
    `,
    styles: [
      {
        id: 'softshadow',
        cssButton: `
          border: 2px solid transparent;
          border-radius: 0;
          box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
        `,
      },
      {
        id: 'softshadowrounded',
        cssButton: `
          border: 2px solid transparent;
          border-radius: 0.5rem;
          box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
        `,
      },
      {
        id: 'softshadowcircular',
        cssButton: `
          border: 2px solid transparent;
          border-radius: 6.25rem;
          box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
        `,
      },
    ],
  },
];

export const backgroundPatternList = [
  {
    id: 'Endless Clouds',
    css: `
      background-color: #c5dff5;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%230b3967' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
    `,
  },
  {
    id: 'Lined Paper',
    css: `
      background-color: #fff;
      background-image:
      linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
      linear-gradient(#eee .1em, transparent .1em);
      background-size: 100% 1.2em;
    `,
  },
  {
    id: 'Diagonal Stripes',
    css: `
      background-color: gray;
      background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px);
    `,
  },
  {
    id: 'Brick Wall',
    css: `
      background-color: #ab4c25;
      background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    `,
  },
  {
    id: 'Stripes',
    css: `
      background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='29' height='29' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='%231c7ed6'/%3E%3Cpath d='M-10 30h60v4h-60zM-10-10h60v4h-60' fill='%23fcc419'/%3E%3Cpath d='M-10 18h60v4h-60zM-10-22h60v4h-60z' fill='%231864ab'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");  
    `,
  },
  {
    id: 'Hearts',
    css: `
      background:
        radial-gradient(circle closest-side at 60% 43%, #b03 26%, rgba(187,0,51,0) 27%),
        radial-gradient(circle closest-side at 40% 43%, #b03 26%, rgba(187,0,51,0) 27%),
        radial-gradient(circle closest-side at 40% 22%, #d35 45%, rgba(221,51,85,0) 46%),
        radial-gradient(circle closest-side at 60% 22%, #d35 45%, rgba(221,51,85,0) 46%),
        radial-gradient(circle closest-side at 50% 35%, #d35 30%, rgba(221,51,85,0) 31%),

        radial-gradient(circle closest-side at 60% 43%, #b03 26%, rgba(187,0,51,0) 27%) 50px 50px,
        radial-gradient(circle closest-side at 40% 43%, #b03 26%, rgba(187,0,51,0) 27%) 50px 50px,
        radial-gradient(circle closest-side at 40% 22%, #d35 45%, rgba(221,51,85,0) 46%) 50px 50px,
        radial-gradient(circle closest-side at 60% 22%, #d35 45%, rgba(221,51,85,0) 46%) 50px 50px,
        radial-gradient(circle closest-side at 50% 35%, #d35 30%, rgba(221,51,85,0) 31%) 50px 50px;
      background-color:#b03;
      background-size:100px 100px;
    `,
  },
];

export const themeOptions = [
  {
    id: 'Mayarian Blue',
    background: {
      type: 'flat',
      backgroundColor: '#ffffff',
      backgroundStyle: 'background-color: #ffffff;',
    },
    buttonStyles: {
      buttonStyleId: 'fillcircular',
      buttonFontColor: '#ffffff',
      buttonColor: '#157af6',
    },
    fontFamily: { fontName: 'Open Sans', fontColor: '#343a40' },
    cardStyles: {
      cardColor: '#ffffff',
      cardFontColor: '#343a40',
    }
  },
  {
    id: 'Mayarian II',
    background: {
      type: 'gradient',
      backgroundGradient: 'radial-gradient(circle at right, #eb1c7c 0.00%,#0c54ec 100.00%)',
      backgroundStyle:
        'background-image: radial-gradient(circle at right, #eb1c7c 0.00%,#0c54ec 100.00%);',
    },
    fontFamily: { fontName: 'Nova Round', fontColor: '#cce5ff' },
    buttonStyles: {
      buttonStyleId: 'hardshadowrounded',
      buttonColor: '#ffffff',
      buttonFontColor: '#7d9dbc',
    },
  },
  {
    id: 'Deep Red',
    background: {
      type: 'flat',
      backgroundColor: '#911f27',
      backgroundStyle: 'background-color: #911f27;',
    },
    buttonStyles: {
      buttonStyleId: 'fillcircular',
      buttonColor: '#fcf0c8',
      buttonFontColor: '#630a10',
    },
    fontFamily: { fontName: 'DM Sans', fontColor: '#face7f' },
  },
  {
    id: 'Hyperspace',
    background: { type: 'animation', backgroundAnimationId: 'Parallax Star' },
    fontFamily: { fontName: 'Nova Round', fontColor: '#cce5ff' },
    buttonStyles: {
      buttonStyleId: 'hardshadowcircular',
      buttonColor: '#ffffff',
      buttonFontColor: '#7d9dbc',
    },
  },
  {
    id: 'Old Sea',
    background: {
      type: 'pattern',
      backgroundPatternId: 'Endless Clouds',
      backgroundStyle: `
        background-color: #c5dff5;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%230b3967' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
      `,
    },
    fontFamily: { fontName: 'Calistoga', fontColor: '#5b7795' },
    buttonStyles: {
      buttonStyleId: 'softshadowcircular',
      buttonColor: '#ffffff',
      buttonFontColor: '#7d9dbc',
    },
  },
  {
    id: 'Pastel Brown',
    background: {
      type: 'flat',
      backgroundColor: '#f9f3df',
      backgroundStyle: 'background-color: #f9f3df;',
    },
    fontFamily: { fontName: 'DM Sans', fontColor: '#31363a' },
    buttonStyles: {
      buttonStyleId: 'fillcircular',
      buttonColor: '#f4d19b',
      buttonFontColor: '#ffffff',
    },
  },
  {
    id: 'Purple',
    background: {
      type: 'gradient',
      backgroundGradient: 'linear-gradient(45deg, #3b185f 0.00%,#38095d 99.00%)',
      backgroundStyle: 'background-image: linear-gradient(45deg, #3b185f 0.00%,#38095d 99.00%);',
    },
    fontFamily: { fontName: 'Nova Round', fontColor: '#fec260' },
    buttonStyles: {
      buttonStyleId: 'softshadowcircular',
      buttonColor: '#a12568',
      buttonFontColor: '#fec260',
    },
  },
  {
    id: 'Solarian',
    background: {
      type: 'gradient',
      backgroundGradient: 'linear-gradient(45deg, #dc1fff 0.00%,#00ffa3 100.00%)',
      backgroundStyle: 'background-image: linear-gradient(45deg, #dc1fff 0.00%,#00ffa3 100.00%);',
    },
    fontFamily: { fontName: 'Nova Round', fontColor: '#343a40' },
    buttonStyles: { buttonStyleId: 'outline', buttonColor: '#ffffff', buttonFontColor: '#ffffff' },
  },
  {
    id: 'Summer',
    background: {
      type: 'flat',
      backgroundColor: '#ff5c58',
      backgroundStyle: 'background-color: #ff5c58;',
    },
    fontFamily: { fontName: 'Nova Round', fontColor: '#ffedd3' },
    buttonStyles: {
      buttonStyleId: 'softshadowcircular',
      buttonColor: '#ffedd3',
      buttonFontColor: '#fe8f8f',
    },
  },
];

export const getButtonStyles = () => {
  return [].concat.apply(
    [],
    buttonStyleOptions.map(({ styles }) => {
      return styles.map((style) => {
        return style;
      });
    }),
  );
};

export const getFonts = () => {
  return [].concat.apply(
    [],
    fontOptions.map(({ fonts }) => {
      return fonts.map((fontName) => {
        return fontName;
      });
    }),
  );
};
