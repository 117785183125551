"use client";

import FacebookPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

export const conversionPageView = (conversionSetting = {}) => {
  console.log({conversionSetting})
  // if (typeof window !== "undefined") {
  //   return
  // }

  if (conversionSetting?.trackingId) {
    const pageView = conversionSetting.conversionSettingEvents?.find(
      (ev) => ev.page === 'PAGEVIEW',
    );

    if (conversionSetting.type === 'GOOGLE') {
      TagManager.initialize({
        gtmId: conversionSetting.trackingId,
        dataLayerName: 'dataLayerProduct',
      });
      TagManager.dataLayer({
        dataLayerName: 'dataLayerProduct',
        dataLayer: {
          event: pageView?.name || 'pageView',
          pageTitle: (typeof window !== "undefined") && window.document.title,
          pageLocation: (typeof window !== "undefined") && window.location.pathname,
        },
      });
    } else if (conversionSetting.type === 'META') {
      FacebookPixel.init(conversionSetting.trackingId);

      if (pageView?.name) {
        FacebookPixel.trackSingleCustom(conversionSetting.trackingId, pageView.name);
      } else {
        FacebookPixel.pageView();
      }
    } else if (conversionSetting.type === 'TIKTOK') {
      TiktokPixel.init(conversionSetting.trackingId);

      if (pageView?.name) {
        TiktokPixel.track(pageView.name);
      } else {
        TiktokPixel.pageView();
      }
    }
  }
};

export const conversionAddPaymentInfo = (conversionSetting, paymentLink, amount, userAnalytics) => {
  // if (typeof window !== "undefined") {
  //   return
  // }

  if (conversionSetting?.trackingId) {
    const addPaymentInfo = conversionSetting.conversionSettingEvents?.find(
      (ev) => ev.page === 'ADDPAYMENTINFO',
    );

    if (conversionSetting.type === 'GOOGLE') {
      const dataLayer = {
        event: addPaymentInfo?.name || 'addPaymentInfo',
        pageTitle: (typeof window !== "undefined") && window.document.title,
        pageLocation: (typeof window !== "undefined") && window.location.pathname,
        productId: paymentLink.id,
        productName: paymentLink.name,
        productType: paymentLink.type,
        value: amount,
        currency: 'IDR',
        quantity: 1,
      };

      const dataLayerName =
        userAnalytics?.gtmId === conversionSetting.trackingId
          ? 'dataLayerGlobal'
          : 'dataLayerProduct';

      if (userAnalytics?.gtmId !== conversionSetting.trackingId) {
        TagManager.initialize({
          gtmId: conversionSetting.trackingId,
          dataLayerName: 'dataLayerProduct',
        });
      }

      TagManager.dataLayer({ dataLayerName, dataLayer });
    } else if (conversionSetting.type === 'META') {
      FacebookPixel.init(conversionSetting.trackingId);

      const data = {
        content_category: paymentLink.type,
        content_ids: [paymentLink.id],
        currency: 'IDR',
        value: amount,
        contents: [
          {
            id: paymentLink.id,
            quantity: 1,
          },
        ],
      };

      if (addPaymentInfo?.name) {
        FacebookPixel.trackSingleCustom(conversionSetting.trackingId, addPaymentInfo.name, data);
      } else {
        FacebookPixel.trackSingle(conversionSetting.trackingId, 'AddPaymentInfo', data);
      }
    } else if (conversionSetting.type === 'TIKTOK') {
      TiktokPixel.init(conversionSetting.trackingId);

      const data = {
        content_type: 'product',
        currency: 'IDR',
        value: amount,
        contents: [
          {
            content_id: paymentLink.id,
            content_name: paymentLink.name,
            content_category: paymentLink.type,
            price: amount,
            quantity: 1,
          },
        ],
      };

      if (addPaymentInfo?.name) {
        TiktokPixel.track(addPaymentInfo.name, data);
      } else {
        TiktokPixel.track('AddPaymentInfo', data);
      }
    }
  }
};

export const conversionInitiateCheckout = (conversionSetting, paymentLink, userAnalytics) => {
  // if (typeof window !== "undefined") {
  //   return
  // }
  console.log({conversionSetting, paymentLink, userAnalytics})
  if (conversionSetting?.trackingId) {
    const initiateCheckout = conversionSetting.conversionSettingEvents?.find(
      (ev) => ev.page === 'INITIATECHECKOUT',
    );

    if (conversionSetting.type === 'GOOGLE') {
      const dataLayer = {
        event: initiateCheckout?.name || 'initiateCheckout',
        pageTitle: (typeof window !== "undefined") && window.document.title,
        pageLocation: (typeof window !== "undefined") && window.location.pathname,
        productId: paymentLink.id,
        productName: paymentLink.name,
        productType: paymentLink.type,
        quantity: 1,
      };

      const dataLayerName =
        userAnalytics?.gtmId === conversionSetting.trackingId
          ? 'dataLayerGlobal'
          : 'dataLayerProduct';

      if (userAnalytics?.gtmId !== conversionSetting.trackingId) {
        TagManager.initialize({
          gtmId: conversionSetting.trackingId,
          dataLayerName: 'dataLayerProduct',
        });
      }

      TagManager.dataLayer({ dataLayerName, dataLayer });
    } else if (conversionSetting.type === 'META') {
      FacebookPixel.init(conversionSetting.trackingId);

      const data = {
        content_category: paymentLink.type,
        content_ids: [paymentLink.id],
        contents: [
          {
            id: paymentLink.id,
            quantity: 1,
          },
        ],
      };

      if (initiateCheckout?.name) {
        FacebookPixel.trackSingleCustom(conversionSetting.trackingId, initiateCheckout.name, data);
      } else {
        FacebookPixel.trackSingle(conversionSetting.trackingId, 'InitiateCheckout', data);
      }
    } else if (conversionSetting.type === 'TIKTOK') {
      TiktokPixel.init(conversionSetting.trackingId);

      const data = {
        content_type: 'product',
        contents: [
          {
            content_id: paymentLink.id,
            content_name: paymentLink.name,
            content_category: paymentLink.type,
          },
        ],
      };

      if (initiateCheckout?.name) {
        TiktokPixel.track(initiateCheckout.name, data);
      } else {
        TiktokPixel.track('InitiateCheckout', data);
      }
    }
  }
};

export const conversionPurchase = (
  conversionSetting,
  isPixelServerSide,
  paymentLinkTransaction,
  userAnalytics,
) => {
  // if (typeof window !== "undefined") {
  //   return
  // }
  console.log({conversionSetting, isPixelServerSide, paymentLinkTransaction, userAnalytics})
  const session = Cookies.get(`session_pyt_${paymentLinkTransaction.id}`);

  if (!session) {
    const fbp = Cookies.get('_fbp') || null;
    const facebookPixelId = userAnalytics?.facebookPixelId;
    const gtmId = userAnalytics?.gtmId;

    const facebookData = {
      fbp: fbp,
      content_type: 'product',
      content_ids: [paymentLinkTransaction.paymentLink.id],
      currency: 'IDR',
      value: paymentLinkTransaction.amount,
      contents: [
        {
          id: paymentLinkTransaction.paymentLink.id,
          quantity: 1,
        },
      ],
    };

    const dataLayer = {
      event: 'purchase',
      pageTitle: (typeof window !== "undefined") && window.document.title,
      pageLocation: (typeof window !== "undefined") && window.location.pathname,
      value: paymentLinkTransaction.amount,
      currency: 'IDR',
      quantity: 1,
      productId: paymentLinkTransaction.paymentLink.id,
      productName: paymentLinkTransaction.paymentLink.name,
      productType: paymentLinkTransaction.paymentLink.type,
      checkoutName: paymentLinkTransaction?.paymentLink?.name,
      checkoutTotal: paymentLinkTransaction?.amount,
      paymentMethod: paymentLinkTransaction?.paymentMethod,
    };

    if (conversionSetting?.trackingId) {
      const purchase = conversionSetting.conversionSettingEvents?.find(
        (ev) => ev.page === 'PURCHASE',
      );

      if (conversionSetting.type === 'GOOGLE') {
        const dataLayerName =
          userAnalytics?.gtmId === conversionSetting.trackingId
            ? 'dataLayerGlobal'
            : 'dataLayerProduct';

        if (userAnalytics?.gtmId !== conversionSetting.trackingId) {
          TagManager.initialize({
            gtmId: conversionSetting.trackingId,
            dataLayerName: 'dataLayerProduct',
          });
        }

        TagManager.dataLayer({
          dataLayerName,
          dataLayer: { ...dataLayer, name: purchase?.name || 'purchase' },
        });
      }

      if (!isPixelServerSide && conversionSetting?.type === 'META') {
        FacebookPixel.init(conversionSetting.trackingId);

        if (purchase?.name) {
          FacebookPixel.trackSingleCustom(
            conversionSetting.trackingId,
            purchase.name,
            facebookData,
          );
        } else {
          FacebookPixel.trackSingle(conversionSetting.trackingId, 'Purchase', facebookData);
        }
      }

      if (!isPixelServerSide && conversionSetting?.type === 'TIKTOK') {
        TiktokPixel.init(conversionSetting.trackingId, {
          email: paymentLinkTransaction.customer.email,
          phone_number: paymentLinkTransaction.customer.mobile,
        });

        const data = {
          currency: 'IDR',
          value: paymentLinkTransaction.amount,
          content_type: 'product',
          contents: [
            {
              content_id: paymentLinkTransaction.paymentLink.id,
              content_name: paymentLinkTransaction.paymentLink.name,
              content_category: paymentLinkTransaction.paymentLink.type,
              quantity: 1,
            },
          ],
        };

        if (purchase?.name) {
          TiktokPixel.track(purchase.name, data);
        } else {
          TiktokPixel.track('Purchase', data);
        }
      }
    }

    if (facebookPixelId && facebookPixelId !== conversionSetting?.trackingId) {
      FacebookPixel.init(facebookPixelId);
      FacebookPixel.trackSingle(facebookPixelId, 'Purchase', facebookData);
    }

    if (gtmId && gtmId !== conversionSetting?.trackingId) {
      TagManager.dataLayer({ dataLayerName: 'dataLayerGlobal', dataLayer });
    }

    Cookies.set(`session_pyt_${paymentLinkTransaction.id}`, true, {
      expires: 7,
    });
  }
};
