"use client";

import React from 'react';
import PaymentLinkDetailComponent from './paymentlink';

const ProductPopUp = ({ id, username, userId, children }) => {
	return <PaymentLinkDetailComponent id={id} username={username} userId={userId} children={children} />
};

export default ProductPopUp;
