"use client";
import "./style.scss"
import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import he from 'he';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'next/navigation';
import ModalShareProduct from '@/components/modals/modal-share-product';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle, CardImage } from '@/components/ui/card';
import { GQL_GET_PAYMENT_LINK_PAGE } from '../../../gqls';
import {
  REGEX_UUID,
  formatRp,
  updatePaginatedData,
  productPageDetail,
  checkSubdomain,
} from '../../../utils';
import { sanitizeDOM } from '@/utils/sanitizer';
import { Icon } from '@iconify/react';
import PoweredBy from '../../powered-by';
import SecureBadge from '../../secure-badge';
import BrokenLink from '../../broken-link';
// import ModalBodyShare from '../../modals/modal-share-product';

const Content = ({ username, id }) => {
  const { t } = useTranslation();
  const search = useSearchParams();

  const subdomainLink = username;
  const oldUrl = id.split("/");
  const gparam = {
    pageSize: 1,
    page: 1,
    username: subdomainLink,
    ...(REGEX_UUID.test(id) ? { id } : { link: oldUrl[2] }),
  };

  const [loadingPage, setLoadingPage] = useState(false);

  const { loading, error, data, fetchMore } = useQuery(GQL_GET_PAYMENT_LINK_PAGE, {
    context: { public: true },
    variables: gparam,
  });

  const updateData = () => {
    setLoadingPage(true);
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: false,
    }).then(() => {
      setLoadingPage(false);
    });
  };

  useEffect(() => {
    updateData();
  }, [id]);

  const paymentLinkPage = _.get(data, 'getPaymentLinkPageByUsername', {});
  const paymentLink = _.get(paymentLinkPage, 'items[0]', {});

  if (error) return <BrokenLink message={t('alert.plsRefresh')} useRefreshButton />;
  if (loading || loadingPage) return <Icon icon="svg-spinners:270-ring-with-bg" className="text-2xl" />;
  if (paymentLinkPage?.errorMessage) {
    return <BrokenLink message={paymentLinkPage?.errorMessage} />;
  }

  const { name, description, coverImage, user, link, status, amount, type } = paymentLink;

  if (status === 'closed' || status === "deleted") {
    return <BrokenLink message={t('alert.closedPaymentLink2')} />;
  }

  return (
    <Fragment>
      {/* <Helmet>
        <title>{`${sanitizeDOM(name)} - ${sanitizeDOM(user?.account?.name)}`}</title>
        {user?.account?.logo ? (
          <link rel="icon" type="image/png" href={handleResizeImageUrl(user?.account?.logo?.url, 300)} />
        ) : null}
      </Helmet> */}
      <div className="bg-image">
        <div className="bg-grey-1" />
      </div>
      <div className="mr-auto ml-auto mt-10 mb-auto w-full">
        <div className="">
          <div>
            <div className='w-full'>
              <Card className="mb-[2px] mt-[2px]">
                <img
                  src={coverImage?.url || '/digital-product-placeholder.webp'}
                  alt="cover image"
                  className="h-[288px] card-img-top-detail w-full object-cover rounded-t-lg"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = '/digital-product-placeholder.webp';
                  }}
                />
                <CardContent className='pt-5'>
                  <div>
                    <div className="mb-[20px] w-full flex flex-col gap-2">
                      <p className="font-md text-[1.2rem]">
                        {name} <br />
                        <small className="text-secondary font-light text-[1rem]">{formatRp(amount)}</small>
                      </p>
                      <div
                        className="break-words desc-editor text-[14px]"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeDOM(description),
                        }}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="checkout-actions">
            <div className="mt-5 mb-0 flex gap-2">
              <div className="mr-0 ">
                <ModalShareProduct
                  url={`${
                    process.env.NODE_ENV === 'development'
                      ? `${process.env.NEXT_PUBLIC_BASE_URL}`
                      : checkSubdomain(username)
                  }${productPageDetail(username, link, type)}`}
                  content={
                    name +
                    '\n\n' +
                    he.decode(sanitizeDOM(description)?.replace(/(<([^>]+)>)/gi, '')) +
                    '\n\nLink: ' +
                    `${productPageDetail(username, link, type)}`
                  }
                >
                  <Button
                    variant="outline-primary"
                    className="text-center text-[0.8rem]"
                  >
                    <Icon icon="weui:share-outlined" className='text-2xl' />
                  </Button>
                </ModalShareProduct>
              </div>
              <div className="ml-0 pl-0 w-full">
                <a href={`/pl/${link}${search && `?${search.toString()}`}`}>
                  <Button className="text-center w-full text-[12px] uppercase" variant="primary">
                    {t('word.payNow')}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <SecureBadge />
        </div>
        {/* MOBILE ONLY */}
        {/* <div className="block bg-white fixed-bottom sm:hidden pt-[15px] pb-[15px] px-[15px]">
          <div className='flex gap-2'>
            <div className="mr-0 w-[25%]">
              <ModalShareProduct
                url={`${
                  process.env.NODE_ENV === 'development'
                    ? `${process.env.REACT_APP_BASE_URL}`
                    : checkSubdomain(username)
                }${productPageDetail(username, link, type)}`}
                content={
                  name +
                  '\n\n' +
                  he.decode(sanitizeDOM(description)?.replace(/(<([^>]+)>)/gi, '')) +
                  '\n\nLink: ' +
                  `${productPageDetail(username, link, type)}`
                }
              >
                <Button
                  variant="outline-primary"
                  className="text-center text-[0.8rem]"
                >
                  <Icon icon="weui:share-outlined" className='text-2xl' />
                </Button>
              </ModalShareProduct>
            </div>
            <div className="ml-0 pl-0 w-full">
              <a href={`/pl/${link}${search && search.toString()}`}>
                <Button className="text-center w-full text-[12px] uppercase" variant="primary">
                  {t('word.payNow')}
                </Button>
              </a>
            </div>
          </div>
        </div> */}
        <div className="powered-by-parent">
          <PoweredBy />
        </div>
      </div>
    </Fragment>
  );
};

export default Content
