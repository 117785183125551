"use client";

import { useRef, useEffect } from "react";
import { Icon } from "@iconify/react";

const TiktokEmbed = ({ url }) => {
  const embedRef = useRef(null);
  useEffect(() => {
    if (embedRef.current) {
      const script = document.createElement("script");
      script.src = "https://www.tiktok.com/embed.js";
      script.async = true;
      embedRef.current.appendChild(script);
    }
  }, [url]);

  return (
    <blockquote
      className="tiktok-embed"
      cite={url}
      data-video-id={url.split("/").pop()}
      ref={embedRef}
    >
      <section>
        <Icon icon="svg-spinners:clock" className="text-center text-2xl" />
      </section>
    </blockquote>
  );
}

export default TiktokEmbed;
