"use client";
import "./style.scss";
import React, { FC, useEffect } from "react";
import { useSearchParams, useRouter } from "next/navigation"; // Import router and search params hooks

import dynamic from "next/dynamic";
// import Icon from "../icon"
import { Icon } from "@iconify/react";
// const Icon = dynamic(() => import("@/components/icon"), { ssr: false });
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { useTranslation } from "react-i18next";
import moment from "moment";
// import idLocale from 'moment/locale/id';
// import enLocale from 'moment/locale/en-au';
import "moment/locale/id"; // Import once globally
import "moment/locale/en-au";

type SecureBadgeType = {
  hideSecure?: boolean;
  hideLanguage?: boolean;
  className?: string;
  classNameBadge?: string;
  version2?: boolean;
};

const SecureBadge: FC<SecureBadgeType> = ({
  hideSecure = false,
  hideLanguage = false,
  className = "",
  classNameBadge = "",
  version2 = false,
}) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const searchParams = useSearchParams(); // Get current query params
  // if (i18n.language === "id") {
  //   // require('moment/locale/id'); // Dynamically require the locale
  //   moment.locale("id");

  //   // moment.updateLocale('id', [idLocale]);
  // } else {
  //   moment.locale("en-au");

  //   // moment.updateLocale('en-au', [enLocale]);
  // }

  useEffect(() => {
    const lang = searchParams.get('lang') || 'id'
    if (lang === 'en') {
      // moment.updateLocale('en-au', [enLocale]);
      moment.locale("en-au");
    } else {
      // moment.updateLocale('id', [idLocale]);
      moment.locale("id");
    }
    // changeLanguage(lang || "id")
  }), []

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    if (language === 'en') {
      // moment.updateLocale('en-au', [enLocale]);
      moment.locale("en-au");
    } else {
      // moment.updateLocale('id', [idLocale]);
      moment.locale("id");
    }

    const currentParams = new URLSearchParams(searchParams.toString());

    // Set the locale parameter in the query
    currentParams.set("lang", language);

    // Update the URL without a full reload using shallow routing
    router.replace(
      `${window.location.pathname}?${currentParams.toString()}`,
      undefined,
    );
  };

  const displaySecure = () => {
    if (version2) {
      return (
        <Card
          className={`mt-[20px] ${className} ${classNameBadge} secure-2 shadow-none`}
          id="badge-secure-mayar"
        >
          <div className="px-[30px] py-[25px]">
            <div className="pay-icons-2">
              <Icon icon="clarity:shield-line" className="text-4xl" />
            </div>
            <div className="secure-2-title">{t("global.safePayment")}</div>
            <div className="secure-2-info">
              <small>{t("global.spDesc")}</small>
            </div>
          </div>
        </Card>
      );
    }

    return (
      <Card
        className={cn(
          "mt-[20px] shadow-none rounded-sm",
          className,
          classNameBadge
        )}
        id="badge-secure-mayar"
      >
        <div className="-mt-[6px] -mb-[6px] px-[13px] py-[15px]">
          <div className="flex flex-wrap -mx[15px] gap-1 items-center">
            <div className="w-[20%] flex">
              <span className="text-green-500 pay-icons m-auto">
                <Icon icon="clarity:shield-line" className="text-[2.5rem]" />
              </span>
            </div>
            <div className="w-[75%]">
              <span className="font-[500] text-[0.8rem]">
                {t("global.safePayment")}
              </span>
              <br />
              <small className="text-[0.7rem] font-light">
                {t("global.spDesc")}
              </small>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      {!hideSecure && displaySecure()}

      {!hideLanguage ? (
        <div
          className="flex justify-center items-center mt-[20px] mb-0 flex-wrap -mx[15px]"
          id="language-mayar"
        >
          <button
            type="button"
            className="hover:underline text-gray-900 text-[9px] relative inline-flex items-stretch py-[9px] px-[15px] uppercase"
            onClick={() => changeLanguage("id")}
          >
            BAHASA
          </button>{" "}
          |
          <button
            type="button"
            className="hover:underline text-gray-900 text-[9px] relative inline-flex items-stretch py-[9px] px-[15px] uppercase"
            onClick={() => changeLanguage("en")}
          >
            ENGLISH
          </button>
        </div>
      ) : null}
    </>
  );
};

export default SecureBadge;
