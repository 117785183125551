import React from 'react';
import { Emoji, EmojiStyle } from "emoji-picker-react";

export const UnitIcon = ({ unitIcon }) => {

		if(unitIcon.iconType === "emoji"){
			return <Emoji
				unified={unitIcon.selectedEmoji}
				emojiStyle={EmojiStyle.APPLE}
				size={40}
			/>
		}

		if(unitIcon.iconType === "image"){
			return <img alt="icon" src={unitIcon.iconImg} width="40"/>
		}
    
    return(
			<></>
    )
}
