"use client";

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Card, CardContent, CardImage, CardTitle, CardDescription } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useQuery } from '@apollo/client';
import {
  updatePaginatedData,
  productOptionsHeader,
  productPageDetail,
  formatRp,
  findCheapestTierPeriodPrice,
  parseJSON,
  getCoverImage,
} from '../../utils';
import { Icon } from '@iconify/react';
import { GQL_GET_PAYMENT_LINK_PAGE } from '../../gqls';
import SubmitButton from '../submit-button';
import SecureBadge from '../secure-badge';
import PoweredBy from '../powered-by';
import CatalogTags from './catalog-tags';

const gparam = {
  pageSize: 9,
  page: 1,
  status: 'active',
  excludeType: 'bundling',
};

const gparamBundling = {
  pageSize: 9,
  page: 1,
  status: 'active',
  type: 'bundling',
};

const Catalog = ({ userData, username }) => {
  const { t } = useTranslation();
  const subdomainLink = username;
  gparam.username = subdomainLink;
  gparamBundling.username = subdomainLink;

  const { data: plPage, fetchMore } = useQuery(GQL_GET_PAYMENT_LINK_PAGE, {
    context: { public: true },
    variables: gparam,
  });
  console.log({plPage, gparam})
  const { data: plPageBundling, fetchMore: fetchMoreBundling } = useQuery(
    GQL_GET_PAYMENT_LINK_PAGE,
    {
      context: { public: true },
      variables: gparamBundling,
    },
  );

  const publicAnnouncement = parseJSON(userData?.publicAnnouncement);
  const catalogProduct = publicAnnouncement?.product
    ? publicAnnouncement.product?.sort((a, b) => a?.position - b?.position)
    : [];
  const [selectedProduct, setSelectedProduct] = useState('');
  const [dropdownProductOpen, setDropdownProductOpen] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPageBundling, setLoadingPageBundling] = useState(false);
  const [displayFilterTag, setDisplayFilterTag] = useState(false);
  const [selectedTagName, setSelectedTagName] = useState('');

  const [errorData, setErrorData] = useState(null);
  const toggleProduct = () => setDropdownProductOpen((prevState) => !prevState);
  const products = _.get(plPage, 'getPaymentLinkPageByUsername', []);
  const bundlingProducts = _.get(plPageBundling, 'getPaymentLinkPageByUsername', []);

  const [heightCardImg, setHeightCardImg] = useState('240px');
  const heightCardMobile = window.innerWidth > 500 && window.innerWidth <= 650 ? '140px' : '120px';
  const handleResize = () => {
    setHeightCardImg(window.innerWidth > 600 ? '240px' : heightCardMobile);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateData = async (type) => {
    try {
      if (type && type === 'bundling') {
        setLoadingPageBundling(true);
        await fetchMoreBundling({
          variables: gparamBundling,
          updateQuery: (previousResult, { fetchMoreResult }) =>
            updatePaginatedData('getPaymentLinkPageByUsername', previousResult, fetchMoreResult),
          notifyOnNetworkStatusChange: false,
        });
      } else {
        console.log("woi")
        setLoadingPage(true);
        await fetchMore({
          variables: gparam,
          updateQuery: (previousResult, { fetchMoreResult }) =>
            updatePaginatedData('getPaymentLinkPageByUsername', previousResult, fetchMoreResult),
          notifyOnNetworkStatusChange: false,
        });
      }
    } catch (errorx) {
      throw errorx;
    } finally {
      if (type && type === 'bundling') {
        setLoadingPageBundling(false);
      } else setLoadingPage(false);
    }
  };

  const handlePageChange = (type, pageSize) => {
    if (type && type === 'bundling') {
      gparamBundling.pageSize = pageSize;
    } else {
      gparam.pageSize = pageSize;
    }
    updateData(type).catch((errorx) => setErrorData(errorx));
  };

  const changeTag = (tag) => {
    gparam.tag = { id: tag.id, name: tag.name };

    updateData();
  };

  const changeProduct = (product) => {
    setDropdownProductOpen(false);
    setSelectedProduct(product);

    if (product !== 'all') {
      gparam.type = product;
    } else {
      gparam.type = null;
    }
    updateData();
  };

  const searchProduct = (value) => {
    gparam.name = value;
    updateData();
  };

  const goToPayLink = (link, type, subType) => {
    let payLink = `/pl/${link}`;

    if (type === 'physical_product') {
      payLink = `/checkout/${link}`;
    }

    if (subType) {
      payLink = `/donate/${link}`;
    }

    return window.location.href = payLink;
  };

  const toggleFilterTag = () => {
    if (gparam.tag) {
      delete gparam.tag;
      updateData();
    }

    setDisplayFilterTag((prevState) => !prevState);
  };
  console.log({plPage})
  if (
    plPage?.getPaymentLinkPageByUsername?.total > 0 ||
    plPageBundling?.getPaymentLinkPageByUsername?.total > 0
  ) {
    return (
      <div className="max-width-category m-auto">
        <div className="bg-transparent public__navbar overflow-auto px-0">
          <div className="bg-gray-100 w-full flex overflow-auto rounded-lg content-center justify-between">
            <div className='h-full'>
              <Button
                variant={selectedProduct === '' ? 'primary' : 'link'}
                className={`m-[7px] h-full items-center text-capitalize text-nowrap text-base text-[14px] ${
                  selectedProduct !== '' ? 'text-black' : 'text-white'
                }`}
                onClick={() => changeProduct('')}
              >
                Semua
              </Button>
              {catalogProduct
                ?.map((item) => ({
                  ...item,
                  label: productOptionsHeader.find((option) => option.value === item.value)?.label,
                }))
                .map((item, index) => {
                  if (index < 3 || selectedProduct === item.value) {
                    return (
                      <Button
                        key={item.value}
                        variant={selectedProduct === item.value ? 'primary' : 'link'}
                        className={`m-[7px] h-full items-center text-capitalize text-nowrap text-base text-[14px]
                ${selectedProduct !== item.value ? '!text-black' : '!text-white'}
                `}
                        onClick={() => changeProduct(item.value)}
                      >
                        {item.label}
                      </Button>
                    );
                  }
                })}

              {publicAnnouncement?.product?.length >= 3 && (
                <Button
                  key="lainnya"
                  variant="link"
                  className={`m-[7px] h-full items-center text-capitalize text-nowrap text-base text-[14px]
                    ${selectedProduct !== 'lainnya' ? '!text-black' : '!text-white'}
                  `}
                  onClick={toggleProduct}
                >
                  Lainnya
                </Button>
              )}
            </div>
            

            <div className='flex items-center gap-7 mr-5'>
              <Icon icon="circum:filter" className='text-lg cursor-pointer' onClick={toggleFilterTag} />
              <div className='relative'>
                <Input
                  placeholder='Cari produk...'
                  className='input-search h-9 text-[12px]'
                  onChange={({ target }) => searchProduct(target.value)}
                  // value={searchVal}
                />
                <Icon icon="ri:search-line" className='absolute text-[1rem] text-gray-400 right-2 top-[0.65rem]' />
              </div>
              
            </div>
          </div>
        </div>

        {dropdownProductOpen && (
          <div>
            <div className="dropdown-menu-product bg-white rounded mx-auto">
              <div>
                {publicAnnouncement?.product
                  ?.map((item) => ({
                    ...item,
                    label: productOptionsHeader.find((option) => option.value === item.value)
                      ?.label,
                  }))
                  .map((item, index) => {
                    if (index >= 3) {
                      return (
                        <div className="text-center w-full" sm={12} key={item.value}>
                          <Button
                            variant={selectedProduct === item.value ? 'primary' : 'link'}
                            className={`m-[7px] items-center text-capitalize text-nowrap text-base ${
                              selectedProduct !== item.value ? '!text-black' : '!text-white'
                            }`}
                            onClick={() => changeProduct(item.value)}
                          >
                            {item.label}
                          </Button>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        )}

        {displayFilterTag && (
          <CatalogTags
            user={userData}
            onChangeTag={changeTag}
            selectedTagName={selectedTagName}
            setSelectedTagName={setSelectedTagName}
          />
        )}

        <div className="my-auto p-0 pb-[5px] pt-[15px]">
          <div className="m-0">
            {products?.items?.length > 0 ? (
              <>
                <div className="grid-products">
                  {products.items?.map((product) => {
                    const { coverImage, link, id, name, amount, type } = product;
                    const multipleImage = product.multipleImage || [];

                    const src = getCoverImage(coverImage, multipleImage, "md");

                    let cheapestMembershipTierPeriod = 0;
                    if (type === 'membership')
                      cheapestMembershipTierPeriod = findCheapestTierPeriodPrice(
                        product?.membershipTier,
                      )?.lowestAmountPeriod;

                    return (
                      <Card className="rounded-store relative" key={id}>
                        <Link
                          href={productPageDetail(
                            product?.user?.paymeLink,
                            link,
                            type,
                            product?.subType,
                          )}
                        >
                          <img
                            src={src}
                            alt="cover image"
                            className="h-[288px] img-cover-store w-full object-cover rounded-t-lg"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = '/digital-product-placeholder.webp';
                            }}
                          />
                          <Badge
                            className="mb-[20px] badge-catalog text-white absolute left-5 top-5"
                            style={{
                              backgroundColor: productOptionsHeader.find(
                                (option) => option.value === type,
                              )?.color,
                            }}
                            variant="dark"
                          >
                            {productOptionsHeader.find((option) => option.value === type)?.label}
                          </Badge>
                          <CardContent className="card-product-bio">
                            <CardTitle className="mb-[30px] font-semibold text-secondary font-title-product">
                              {name}
                            </CardTitle>
                            <div className="flex justify-between items-center price-buy-product mt-5">
                              <div md={9} lg={9} className="w-[25%] items-start">
                                <CardDescription className="font-semibold text-dark h4 m-0 text-truncate">
                                  {formatRp(
                                    type === 'membership' ? cheapestMembershipTierPeriod : amount,
                                  )}
                                </CardDescription>
                              </div>
                              <div className="btn-buy-bio items-end">
                                <div className="flex">
                                  <Button
                                    variant="primary"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                      goToPayLink(link, type, product?.subType);
                                    }}
                                    className="w-full flex justify-center"
                                  >
                                    Beli
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Link>
                      </Card>
                    );
                  })}
                </div>
                {(gparam.pageSize < plPage.getPaymentLinkPageByUsername.total || loadingPage) && (
                  <div className="flex justify-center mb-[20px] mt-[20px] w-full">
                    <SubmitButton
                      outline
                      color="outline-primary"
                      className="text-center"
                      onClick={() => handlePageChange('all', gparam.pageSize + 9)}
                      text={t('paymentLink.showMore')}
                      loading
                      isLoading={loadingPage}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="mx-auto">
                <h3 className="font-bold text-secondary">{t('word.productNotFound')}</h3>
              </div>
            )}
            {errorData && (
              <div className="mx-auto">
                <h3 className="font-bold text-secondary">{errorData}</h3>
              </div>
            )}
          </div>
        </div>

        {bundlingProducts?.items?.length > 0 && (
          <div className="my-auto p-0 pb-[5px] pt-[15px] mt-[18px] mb-[10px]">
            <h1 className="text-[1.2rem] my-5">{t('word.bundlingSaveMore')}</h1>
            <div className="m-0">
              <div className="grid-products">
                {bundlingProducts.items?.map((product) => {
                  const { coverImage, link, id, name, amount, type } = product;
                  const multipleImage = product.multipleImage || [];
                  const src =
                    coverImage?.url || multipleImage[0]?.url || '/digital-product-placeholder.webp';

                  return (
                    <Card className="rounded-store relative" key={id}>
                      <Link
                        href={productPageDetail(
                          product?.user?.paymeLink,
                          link,
                          type,
                          product?.subType,
                        )}
                      >
                        <img
                          src={src}
                          alt="cover image"
                          className="h-[288px] img-cover-store w-full object-cover rounded-t-lg"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/digital-product-placeholder.webp';
                          }}
                        />
                        <Badge
                          className="mb-[20px] badge-catalog text-white absolute top-5 left-5"
                          style={{
                            backgroundColor: productOptionsHeader.find(
                              (option) => option.value === type,
                            )?.color,
                          }}
                          variant="dark"
                        >
                          {productOptionsHeader.find((option) => option.value === type)?.label}
                        </Badge>
                        <CardContent className="card-product-bio">
                          <CardTitle className="mb-[10px] font-semibold text-secondary font-title-product">
                            {name}
                          </CardTitle>
                          <div className="flex justify-between items-center price-buy-product mt-5">
                            <div className="items-start">
                              <CardDescription className="font-weight-bold text-dark h4 m-0 text-truncate">
                                {formatRp(amount)}
                              </CardDescription>
                            </div>
                            <div className="btn-buy-bio items-end">
                              <div className="flex">
                                <Button
                                  variant="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    goToPayLink(link, type, product?.subType);
                                  }}
                                  className="w-full flex justify-center"
                                >
                                  Beli
                                </Button>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Link>
                    </Card>
                  );
                })}
              </div>
              {(gparamBundling.pageSize < plPageBundling.getPaymentLinkPageByUsername.total ||
                loadingPageBundling) && (
                <div className="flex justify-center mb-[20px] mt-[20px]">
                  <SubmitButton
                    outline
                    color="outline-primary"
                    className="text-center"
                    onClick={() => handlePageChange('bundling', gparamBundling.pageSize + 9)}
                    text={t('paymentLink.showMore')}
                    loading
                    isLoading={loadingPageBundling}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className={` mx-0 ${products?.items?.length > 0 && 'mt-auto'}`}>
          <div className="container pt-[10px]">
            <SecureBadge className="bg-transparent" />
            <PoweredBy />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Catalog;
