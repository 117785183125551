"use client";

import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { copyToClipboard } from "@/utils";
import dynamic from "next/dynamic";
import { Icon } from "@iconify/react";
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
// const Button = dynamic(() => import("@/components/ui/button"), {
//   ssr: false,
// });

type ModalShareProductType = {
  url: string;
  content?: string;
  children: React.ReactNode;
};

const ModalShareProduct: FC<ModalShareProductType> = ({
  url,
  content,
  children,
}) => {
  const { t } = useTranslation();
  const [buttonCopy2, setButtonCopy2] = useState(t("global.copyClipboard"));

  const handleCopy2 = (textAreaId: string) => {
    copyToClipboard(textAreaId);
    setButtonCopy2("Copied!");
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className="fixed top-36 left-1/2 transform -translate-x-1/2 
              bg-white p-6 rounded-lg shadow-lg max-w-lg w-full 
              border border-gray-300 z-[1000]"
      >
        <DialogHeader>
          <DialogTitle className="font-medium">Share</DialogTitle>
        </DialogHeader>
        <div>
          <div className="flex flex-col gap-2">
            <Input
              id="pllink2"
              value={url}
              className="bg-gray-200"
              type="text"
              readOnly
            />
            <Button
              className="uppercase w-full text-[12px] border-black"
              variant={"outline"}
              onClick={() => handleCopy2("pllink2")}
            >
              {buttonCopy2}
            </Button>
          </div>
          <hr className="my-5" />
          <div className="flex gap-2 justify-center">
            <FacebookShareButton url={url}>
              <FacebookIcon size={32} round className="" />
            </FacebookShareButton>
            <LineShareButton url={url}>
              <LineIcon size={32} round className="" />
            </LineShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={32} round className="" />
            </LinkedinShareButton>
            <TelegramShareButton url={url}>
              <TelegramIcon size={32} round className="" />
            </TelegramShareButton>
            <TwitterShareButton url={url}>
              <TwitterIcon size={32} round className="" />
            </TwitterShareButton>
            <WhatsappShareButton url={content ? content : url}>
              <WhatsappIcon size={32} round className="" />
            </WhatsappShareButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalShareProduct;
