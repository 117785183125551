import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountdown } from '../../hooks/useCountdown';
import { Flippen } from './flippen';
import './style.scss';

const ComingSoon = ({ startDate, customMessage, isRemoveTopLine }) => {
  const { t } = useTranslation();

  const [days, hours, minutes, seconds] = useCountdown(startDate);

  useEffect(() => {
    if (typeof window !== "undefined" && new Date().getTime() > startDate) {
      window.location.reload();
    }
  }, [seconds]);

  return (
    <>
      <div className="text-center w-full pt-[20px] px-[10px]">
        {isRemoveTopLine ? '' : <hr className="solid" />}
        <h4 className="my-5 font-[400]">{customMessage || t('word.salesOpenOn')}</h4>
        <div className='flex flex-wrap text-[12px] w-full font-[400]'>
          <div xs="3" className="p-0 max-w-[25%] basis-[25%] relative">
            <h3 className=" mb-[5px] font-bold label-size">{t('word.days')}</h3>
            <h1 className="flippen-size">
              <Flippen value={days < 10 ? `0${days}` : days} />
            </h1>
          </div>
          <div xs="3" className="p-0 max-w-[25%] basis-[25%] relative">
            <h3 className=" mb-[5px] font-bold label-size">{t('word.hours')}</h3>
            <h1 className="flippen-size">
              <Flippen value={hours < 10 ? `0${hours}` : hours} />
            </h1>
          </div>
          <div xs="3" className="max-w-[25%] basis-[25%] relative px-[1px]">
            <div className='flex'>
              <div className="p-0 flex items-end basis-[8.33333%] max-w-[8.33333%] relative">
                <h1>:</h1>
              </div>
              <div className="p-0 flex-grow-1 max-w-full relative">
                <h3 className=" mb-[5px] font-bold label-size">{t('word.minutes')}</h3>
                <h1 className="flippen-size">
                  <Flippen value={minutes < 10 ? `0${minutes}` : minutes} />
                </h1>
              </div>
            </div>
          </div>
          <div xs="3" className="max-w-[25%] basis-[25%] relative px-[1px]">
            <div className='flex'>
              <div xs="1" className="p-0 flex items-end basis-[8.33333%] max-w-[8.33333%] relative">
                <h1>:</h1>
              </div>
              <div className="p-0 flex-grow-1 max-w-full relative">
                <h3 className=" mb-[5px] font-bold label-size">{t('word.seconds')}</h3>
                <h1 className="flippen-size">
                  <Flippen value={seconds < 10 ? `0${seconds}` : seconds} />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
