import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generateRandomString, REGEX_URL, slug, validator as defaultValidator } from '../../utils';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import DatePicker from '../date-time-picker';
import { Input } from '../ui/input';
import { ErrorFeedback } from '../ui/error-feedback';
import { Label } from '../ui/label';

let globalCustomeField = null;
const uniqueIds = Array.from(Array(100).keys()).map(() => generateRandomString(4));

export const getCustomeField = () => globalCustomeField;

const CustomFormBuilder = ({ form = {}, formState = {}, isLabelRequired = false }) => {
  const { t } = useTranslation();
  const { fieldObject } = form;
  const { register, control, formState: formUtil } = formState;
  const { errors } = formUtil
  const fields = fieldObject ? JSON.parse(fieldObject) : [];
  console.log({customerror: errors})
  const [customeField, setCustomeField] = useState(fields);

  const validator = {
    ...defaultValidator,
    isRequired: (isRequired) => ({ value: isRequired, message: 'Wajib diisi' }),
    url: { value: REGEX_URL, message: 'Url yang Anda masukkan tidak valid' },
  };

  const updateCustomeField = (field) => {
    const fieldIndex = customeField.findIndex((item) => item.key === field.key);
    customeField[fieldIndex] = field;
    setCustomeField(customeField);
  };

  useEffect(() => {
    if (customeField.length > 0) {
      globalCustomeField = customeField;
    }
  }, [customeField]);

  return fields.map((field, indexField) => {
    const { fieldType, name, key, isRequired, description } = field;
    const fieldOptions = field.value || [];
    const inputId = `${slug(name)}-${uniqueIds[indexField]}`;

    switch (fieldType) {
      case 'text':
        return (
          <div className='w-full pt-[20px] px-[10px]' key={key}>
            {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
            <Input
              id={inputId}
              name={inputId}
              type="text"
              autoComplete="on"
              aria-describedby={`${inputId}-hint`}
              className=" checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]"
              placeholder={isLabelRequired ? '' : name}
              invalid={Boolean(errors[inputId])}
              {...register(inputId, { required: validator.isRequired(isRequired) })}
              onChange={({ target }) => {
                const updatedField = { ...field, value: target.value };
                updateCustomeField(updatedField);
              }}
            />
            <ErrorFeedback id={`${inputId}-hint`}>{errors[inputId]?.message}</ErrorFeedback>
            {errors[inputId]?.message && <br />}
            <small>{description}</small>
          </div>
        );
      case 'email':
        return (
          <div className='w-full pt-[20px] px-[10px]' key={key}>
            {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
            <Input
              id={inputId}
              name={inputId}
              type="email"
              autoComplete="email"
              aria-describedby={`${inputId}-hint`}
              className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]"
              placeholder={isLabelRequired ? '' : name}
              invalid={Boolean(errors[inputId])}
              {...register(inputId, {
                required: validator.isRequired(isRequired),
                pattern: validator.email,
              })}
              onChange={({ target }) => {
                const updatedField = { ...field, value: target.value };
                updateCustomeField(updatedField);
              }}
            />
            <ErrorFeedback id={`${inputId}-hint`}>{errors[inputId]?.message}</ErrorFeedback>
            {errors[inputId]?.message && <br />}
            <small>{description}</small>
          </div>
        );
      case 'tel':
        return (
          <div className='w-full pt-[20px] px-[10px]' key={key}>
            {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
            <Input
              id={inputId}
              name={inputId}
              type="tel"
              autoComplete="tel"
              aria-describedby={`${inputId}-hint`}
              className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]"
              placeholder={isLabelRequired ? '' : name}
              invalid={Boolean(errors[inputId])}
              {...register(inputId, {
                required: validator.isRequired(isRequired),
                setValueAs: validator.formatPhone,
                pattern: validator.phone,
                minLength: validator.minLength(10),
                maxLength: validator.maxLength(15),
              })}
              onChange={({ target }) => {
                const updatedField = { ...field, value: target.value };
                updateCustomeField(updatedField);
              }}
            />
            <ErrorFeedback id={`${inputId}-hint`}>{errors[inputId]?.message}</ErrorFeedback>
            {errors[inputId]?.message && <br />}
            <small>{description}</small>
          </div>
        );
      case 'url':
        return (
          <div className='w-full pt-[20px] px-[10px]' key={key}>
            {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
            <Input
              id={inputId}
              name={inputId}
              type="url"
              autoComplete="on"
              aria-describedby={`${inputId}-hint`}
              className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]"
              placeholder={isLabelRequired ? '' : name}
              invalid={Boolean(errors[inputId])}
              {...register(inputId, {
                required: validator.isRequired(isRequired),
                pattern: validator.url,
              })}
              onChange={({ target }) => {
                const updatedField = { ...field, value: target.value };
                updateCustomeField(updatedField);
              }}
            />
            <ErrorFeedback id={`${inputId}-hint`}>{errors[inputId]?.message}</ErrorFeedback>
            {errors[inputId]?.message && <br />}
            <small>{description}</small>
          </div>
        );
      case 'multipleText':
        return (
          <div className='w-full pt-[20px] px-[10px]' key={key}>
            {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
            <Input
              id={inputId}
              name={inputId}
              type="textarea"
              autoComplete="on"
              aria-describedby={`${inputId}-hint`}
              className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]"
              placeholder={isLabelRequired ? '' : name}
              invalid={Boolean(errors[inputId])}
              {...register(inputId, { required: validator.isRequired(isRequired) })}
              onChange={({ target }) => {
                const updatedField = { ...field, value: target.value };
                updateCustomeField(updatedField);
              }}
            />
            <ErrorFeedback id={`${inputId}-hint`}>{errors[inputId]?.message}</ErrorFeedback>
            {errors[inputId]?.message && <br />}
            <small>{description}</small>
          </div>
        );
      case 'textNumber':
        return (
          <div className='w-full pt-[20px] px-[10px]' key={key}>
            {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
            <Input
              id={inputId}
              name={inputId}
              type="number"
              autoComplete="on"
              aria-describedby={`${inputId}-hint`}
              className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]"
              placeholder={isLabelRequired ? '' : name}
              invalid={Boolean(errors[inputId])}
              {...register(inputId, { required: validator.isRequired(isRequired) })}
              onChange={({ target }) => {
                const updatedField = { ...field, value: target.value };
                updateCustomeField(updatedField);
              }}
            />
            <ErrorFeedback id={`${inputId}-hint`}>{errors[inputId]?.message}</ErrorFeedback>
            {errors[inputId]?.message && <br />}
            <small>{description}</small>
          </div>
        );
      case 'date':
        return (
          <div className='w-full pt-[20px] px-[10px]' key={key}>
            {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
            <Controller
              name={inputId}
              control={control}
              defaultValue={null}
              rules={{ required: isRequired }}
              render={({ field: fieldController, fieldState, formState }) => (
                <DatePicker
                  name={fieldController.name}
                  className={`form-control form-control-lg checkout-input border-[1px] border-[#e6ecf0] w-full ${
                    Boolean(formState.errors[inputId]) ? 'is-invalid' : ''
                  }`}
                  placeholderText={isLabelRequired ? '' : name}
                  selected={fieldController.value}
                  onChange={(date) => {
                    fieldController.onChange(date);
                    const updatedField = { ...field, value: date };
                    updateCustomeField(updatedField);
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              )}
            />
            <div className={Boolean(errors[inputId]) ? 'is-invalid' : ''} />
            <ErrorFeedback id={`${inputId}-hint`}>
              {Boolean(errors[inputId]) && validator.isRequired(isRequired)?.message}
            </ErrorFeedback>
            {validator.isRequired(isRequired)?.message && <br />}
            <small>{description}</small>
          </div>
        );
      case 'checkbox':
        if (fieldOptions.length === 1) {
          return (
            <div className='w-full pt-[20px] px-[10px]' key={key}>
              <div className='flex gap-3'>
                {/* <Controller
                  name={inputId}
                  control={control}
                  rules={{ required: validator.isRequired(isRequired) }}
                  render={({ onChange, name: inputName, value }) => (
                    // <Checkbox
                    //   {...field} // Spread the field props from react-hook-form
                    //   checked={field.value} // Use checked for the checkbox state
                    //   onCheckedChange={field.onChange} // Use onCheckedChange to handle changes
                    // />
                    <Checkbox
                      id={inputId}
                      name={inputName}
                      type="checkbox"
                      label={name}
                      aria-describedby={`${inputId}-hint`}
                      invalid={Boolean(errors[inputId])}
                      {...register(inputId, { required: validator.isRequired(isRequired) })}
                      onChange={({ target }) => {
                        console.log({target})
                        onChange(target.value)
                        const updatedField = { ...field };
                        updatedField.value[0].selected = target.checked;
                        updateCustomeField(updatedField);
                      }}
                      className='w-[1.3rem] h-[1.3rem] bg-slate-200'
                    />
                  )}
                /> */}
                <Input
                  id={inputId}
                  name={inputId}
                  type="checkbox"
                  label={name}
                  aria-describedby={`${inputId}-hint`}
                  invalid={Boolean(errors[inputId])}
                  {...register(inputId, { required: validator.isRequired(isRequired) })}
                  onChange={({ target }) => {
                    console.log({target})
                    // onChange(target.value)
                    const updatedField = { ...field };
                    updatedField.value[0].selected = target.checked;
                    updateCustomeField(updatedField);
                  }}
                  className='w-[1rem] h-[1rem] bg-slate-500 border-[1px] border-slate-400'
                />
                <Label
                  htmlFor={inputId}
                  className="text-sm font-light leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-secondary"
                >
                  {name}
                </Label>
              </div>
              
              <div className={Boolean(errors[inputId]) ? 'is-invalid' : ''} />
              <ErrorFeedback id={`${inputId}-hint`}>{errors[inputId]?.message}</ErrorFeedback>
              {errors[inputId]?.message && <br />}
              <small>{description}</small>
            </div>
          );
        } else if (fieldOptions.length > 1) {
          return (
            <div className='w-full pt-[20px] px-[10px]' key={key}>
              {fieldOptions.map(({ value, name: label }, index) => {
                const checkboxId = slug(label);

                return (
                  <div key={value}>
                    <Input
                      id={checkboxId}
                      name={checkboxId}
                      type="checkbox"
                      label={label}
                      aria-describedby={`${checkboxId}-hint`}
                      invalid={Boolean(errors[checkboxId])}
                      {...register(checkboxId, { required: validator.isRequired(isRequired) })}
                      onChange={({ target }) => {
                        const updatedField = { ...field };
                        updatedField.value[index].selected = target.checked;
                        updateCustomeField(updatedField);
                      }}
                    />
                    <div className={Boolean(errors[checkboxId]) ? 'is-invalid' : ''} />
                    <ErrorFeedback id={`${checkboxId}-hint`}>
                      {errors[checkboxId]?.message}
                    </ErrorFeedback>
                  </div>
                );
              })}
              <small>{description}</small>
            </div>
          );
        }
        break;
      case 'multipleSelect':
        if (fieldOptions.length > 0) {
          const options = fieldOptions.map(({ value, name: label }) => ({ value, label }));

          return (
            <div className='w-full pt-[20px] px-[10px]' key={key}>
              <Controller
                name={inputId}
                control={control}
                defaultValue={null}
                rules={{ required: validator.isRequired(isRequired) }}
                render={({ field: fieldController, fieldState, formState }) => (
                  <Select
                    name={fieldController.name}
                    options={options}
                    isMulti
                    placeholder={t('word.choose') + name}
                    aria-describedby={`${inputId}-hint`}
                    value={options.find(({ value: optionValue }) => optionValue === fieldController.value)}
                    styles={Boolean(formState.errors[inputId]) ? CustomStylesInvalid : CustomStyles}
                    className={`text-left mt-5 form-control-lg p-0 checkout-input ${
                      Boolean(formState.errors[inputId]) ? 'is-invalid' : ''
                    }`}
                    onChange={(e) => {
                      fieldController.onChange(e.value);
                      const updatedField = { ...field };
                      const index = field.value.findIndex((obj) => obj.value === e.value);
                      updatedField.value = updatedField.value.map((obj) => ({
                        ...obj,
                        selected: false,
                      }));

                      updatedField.value[index].selected = true;
                      updateCustomeField(updatedField);
                    }}
                  />
                )}
              />
              <div className={Boolean(errors[inputId]) ? 'is-invalid' : ''} />
              <ErrorFeedback id={`${inputId}-hint`}>
                {validator.isRequired(isRequired)?.message}
              </ErrorFeedback>
              <small>{description}</small>
            </div>
          );
        }
        break;
      case 'select':
        if (fieldOptions.length > 0) {
          const options = fieldOptions.map(({ value, name: label }) => ({ value, label }));

          return (
            <div className='w-full pt-[20px] px-[10px]' key={key}>
              {isLabelRequired ? <Label for={inputId}>{name}</Label> : null}
              <Controller
                name={inputId}
                control={control}
                defaultValue={null}
                rules={{ required: validator.isRequired(isRequired) }}
                render={({ field: fieldController, fieldState, formState }) => (
                  <Select
                    name={fieldController.name}
                    placeholder={isLabelRequired ? '' : name}
                    options={options}
                    aria-describedby={`${inputId}-hint`}
                    value={options.find(({ value: optionValue }) => optionValue === fieldController.value)}
                    styles={Boolean(formState.errors[inputId]) ? CustomStylesInvalid : CustomStyles}
                    className={`text-left form-control-lg p-0 checkout-input ${
                      Boolean(formState.errors[inputId]) ? 'is-invalid' : ''
                    }`}
                    onChange={(e) => {
                      fieldController.onChange(e.value);
                      const updatedField = { ...field };
                      const index = field.value.findIndex((obj) => obj.value === e.value);
                      updatedField.value = updatedField.value.map((obj) => ({
                        ...obj,
                        selected: false,
                      }));

                      updatedField.value[index].selected = true;
                      updateCustomeField(updatedField);
                    }}
                  />
                )}
              />
              <div className={Boolean(errors[inputId]) ? 'is-invalid' : ''} />
              <ErrorFeedback id={`${inputId}-hint`}>
                {Boolean(errors[inputId]) && validator.isRequired(isRequired)?.message}
              </ErrorFeedback>
              <small>{description}</small>
            </div>
          );
        }
        break;
    }
  });
};

export default CustomFormBuilder;
