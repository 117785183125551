"use client"; // Mark this as a client component

import { useEffect } from "react";
import { updateCurrentUser } from "@/lib/redux/features/user";
import { IUser } from "@/composables/user.type";
import { useAppDispatch } from "@/lib/redux/hook";

type UserDispatcherProps = {
  user: IUser; // Type your user data appropriately
};

export default function UserDispatcher({
  user,
}: UserDispatcherProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      // Dispatch the current user to Redux store
      dispatch(updateCurrentUser(user));
    }
  }, [user, dispatch]);

  return null; // This component doesn't need to render anything
}
