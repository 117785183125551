"use client";

import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { useQuery } from '@apollo/client';
import { GET_TAGS_PRODUCTS } from '../../gqls';
import { updateFetchMore } from '../../utils';
import { useTranslation } from 'react-i18next';

const defaultValueParam = {
  page: 1,
  pageSize: 10,
  search: {
    status: [{ operator: 'eq', value: 'ACTIVE' }],
  },
};

let gparam = { ...defaultValueParam };

const CatalogTags = ({
  isPublicProfile = false,
  user = {},
  onChangeTag,
  selectedTagName,
  setSelectedTagName,
  classText = 'text-black',
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);

  gparam.userId = user.id;
  gparam.search.userId = [{ operator: 'eq', value: user.id }];

  const { data, loading, error, fetchMore } = useQuery(GET_TAGS_PRODUCTS, {
    variables: gparam,
    context: { public: true },
    fetchPolicy: 'network-only',
    skip: !user.id,
  });

  const [isTagEmpty, setIsTagEmpty] = useState(false);

  const loadMoreTags = () => {
    gparam.page++;

    updateFetchMore(fetchMore, gparam, 'getListTagsProducts');
  };

  useEffect(() => {
    const tagsData = data?.getListTagsProducts.tags || [];

    setTags((prevState) => [...prevState, ...tagsData]);
  }, [data]);

  const changeTag = (value) => {
    const newValue = value.name === selectedTagName ? {} : value;

    setSelectedTagName(newValue.name);
    onChangeTag(newValue);
  };

  useEffect(() => {
    return () => {
      gparam = { ...defaultValueParam };
      setTags([]);
      setSelectedTagName('');
    };
  }, []);

  useEffect(() => {
    setIsTagEmpty(!loading && !error && !tags.length);
  }, [tags, loading, error]);

  if (!isTagEmpty && !tags.length) return null;

  return (
    <div className={`bg-transparent px-0 min-h-[100%] ${isPublicProfile ? 'mb-[15px]' : 'pt-[10px]'}`}>
      <div className="bg-gray-100 rounded-lg catalog-tags text-nowrap px-[20px] py-[6px]">
        {isTagEmpty ? (
          <div className="flex justify-center p-[15px]">{t('public.noTag')}.</div>
        ) : (
          <div className="flex m-[7px] items-center">
            <div className="font-semibold mr-[15px] !text-[14px]">{t('public.tagTitle')} : </div>
            {tags.map((item, index) => {
              return (
                <Badge
                  key={index}
                  className={`py-[5px] px-[15px] bg-primary-transparent mr-[10px] cursor-pointer ${
                    selectedTagName === item.name ? 'chosen-tag' : '!bg-[#e7f1fd] !text-[#007bff]'
                  }`}
                  onClick={() => changeTag(item)}
                  variant={'primary'}
                >
                  <span
                    className={`font-semibold text-primary !text-[14px] catalog-tags-text text-capitalize ${
                      selectedTagName === item.name && 'chosen-tag-text'
                    }`}
                  >
                    {item.name}
                  </span>
                </Badge>
              );
            })}

            {data?.getListTagsProducts?.nextPage && !loading && (
              <Button
                key="lainnya"
                color="link"
                className={`m-[7px] h-full items-center text-capitalize text-nowrap text-base ${classText}`}
                onClick={loadMoreTags}
              >
                {t('public.other')}...
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CatalogTags;
