import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingAnimation = (props) => {
  if (props.mayar) {
    return (
      <ContentLoader
        speed={1}
        width={340}
        height={84}
        viewBox="0 0 340 84"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <path d="M 93.13 98.8 l 4.65 -30.6 c 1.41 -9.18 3.32 -20.67 6.03 -34.76 h -0.39 c -4.98 12.64 -10.29 24.95 -14.39 33.65 l -13.6 29.87 H 58.87 l -1.41 -29.8 c -0.41 -8.3 -1 -20.81 -1.16 -33.72 h -0.26 c -2.71 13.12 -5.22 25.65 -7.35 34.76 l -7.13 30.6 H 22.2 l 22.22 -88.72 H 71.8 l 0.99 29.88 c 0.27 7.97 1.02 18.72 0.89 28.43 h 0.47 c 3.18 -9.72 7.83 -20.82 11.23 -28.57 l 12.6 -29.74 h 27.53 L 113.44 98.8 H 93.13 z" />
        <path d="M 71.79 9.95 H 7.71 S 61.5 25.58 73.04 45.67 L 71.79 9.95 z" />
        <rect x="137" y="11" rx="0" ry="0" width="200" height="10" />
        <rect x="137" y="31" rx="0" ry="0" width="152" height="10" />
        <rect x="137" y="53" rx="0" ry="0" width="123" height="10" />
        <rect x="137" y="71" rx="0" ry="0" width="152" height="10" />
      </ContentLoader>
    );
  }
  if (props.table) {
    return (
      <ContentLoader viewBox="0 0 400 75">
        <rect x="0" y="0" rx="0" ry="0" width="400" height="1" />
        <rect x="0" y="19" rx="0" ry="0" width="400" height="1" />
        <rect x="0" y="24" rx="0" ry="0" width="400" height="3" />
        <rect x="0" y="31" rx="0" ry="0" width="23" height="3" />
        <rect x="0" y="43" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="43" rx="0" ry="0" width="200" height="1" />
        <rect x="0" y="54" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="54" rx="0" ry="0" width="200" height="1" />
        <rect x="0" y="67" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="67" rx="0" ry="0" width="200" height="1" />
        <rect x="0" y="80" rx="0" ry="0" width="199" height="1" />
        <rect x="200" y="80" rx="0" ry="0" width="200" height="1" />
        <rect x="32" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="86" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="170" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="239" y="5" rx="0" ry="0" width="10" height="10" />
        <rect x="341" y="5" rx="0" ry="0" width="41" height="10" />
      </ContentLoader>
    );
  }
  if (props.fadingload) {
    return (
      <div>
        <FadingLoaderCard1 />
        <FadingLoaderCard2 />
        <FadingLoaderCard3 />
        <FadingLoaderCard4 />
        <FadingLoaderCard5 />
      </div>
    );
  }
  if (props.doordash) {
    return (
      <ContentLoader
        width={450}
        height={400}
        viewBox="0 0 450 400"
        backgroundColor="#f0f0f0"
        foregroundColor="#dedede"
      >
        <rect x="43" y="304" rx="4" ry="4" width="271" height="9" />
        <rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
        <rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
      </ContentLoader>
    );
  }
  if (props.three) {
    return (
      <ContentLoader
        viewBox="0 0 400 160"
        height={160}
        width={400}
        backgroundColor="transparent"
        {...props}
      >
        <circle cx="150" cy="86" r="8" />
        <circle cx="194" cy="86" r="8" />
        <circle cx="238" cy="86" r="8" />
      </ContentLoader>
    );
  }
  if (props.twodashline) {
    return (
      <ContentLoader viewBox="0 0 100 31" height={31} width="100%" preserveAspectRatio="none">
        <rect height="5.5" rx="1" ry="1" width="100%" x="0" y="5" />
        <rect height="5.5" rx="1" ry="1" width="100%" x="0" y="15" />
      </ContentLoader>
    );
  }
  if (props.sidebar) {
    return (
      <ContentLoader viewBox="0 0 300 615" height={615} width="100%" preserveAspectRatio="none">
        <rect x="79" y="20" rx="0" ry="0" width="0" height="1" />
        <rect x="4" y="1" rx="0" ry="0" width="3" height="600" />
        <rect x="4" y="598" rx="0" ry="0" width="294" height="3" />
        <rect x="158" y="596" rx="0" ry="0" width="5" height="3" />
        <rect x="5" y="1" rx="0" ry="0" width="294" height="3" />
        <rect x="296" y="1" rx="0" ry="0" width="3" height="600" />
        <rect x="5" y="60" rx="0" ry="0" width="294" height="3" />
        <rect x="22" y="20" rx="0" ry="0" width="129" height="23" />
        <rect x="35" y="76" rx="4" ry="4" width="81" height="9" />
        <rect x="271" y="22" rx="4" ry="4" width="18" height="18" />
        <rect x="186" y="76" rx="4" ry="4" width="81" height="9" />
        <rect x="150" y="63" rx="0" ry="0" width="2" height="44" />
        <rect x="6" y="104" rx="0" ry="0" width="144" height="3" />
        <rect x="152" y="106" rx="0" ry="0" width="145" height="1" />
        <rect x="28" y="127" rx="4" ry="4" width="243" height="31" />
        <rect x="62" y="188" rx="4" ry="4" width="148" height="19" />
        <circle cx="39" cy="197" r="10" />
        <circle cx="39" cy="247" r="10" />
        <circle cx="39" cy="297" r="10" />
        <circle cx="39" cy="347" r="10" />
        <rect x="64" y="237" rx="4" ry="4" width="148" height="19" />
        <rect x="65" y="287" rx="4" ry="4" width="148" height="19" />
        <rect x="64" y="337" rx="4" ry="4" width="148" height="19" />
        <circle cx="39" cy="547" r="10" />
      </ContentLoader>
    );
  }

  if (props.profilecard) {
    return (
      <ContentLoader
        speed={1}
        width={650}
        height={800}
        viewBox="170 100 450 400"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="277" cy="145" r="55" />
        <rect x="227" y="210" rx="0" ry="0" width="110" height="15" />
        <rect x="239" y="235" rx="0" ry="0" width="85" height="10" />
        <rect x="185" y="290" rx="0" ry="0" width="190" height="20" />
        <rect x="185" y="335" rx="0" ry="0" width="190" height="20" />
        <rect x="185" y="380" rx="0" ry="0" width="190" height="20" />
      </ContentLoader>
    );
  }
  if (props.responsivearticle) {
    return (
      <ContentLoader viewBox="0 0 100 650" height={'auto'} width={'100%'} {...props}>
        <rect x="0" y="0" rx="5" ry="5" width="40%" height="20" />
        <rect x="0" y="42" rx="5" ry="5" width="100%" height="200" />
        <rect x="0" y="265" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="285" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="305" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="335" rx="5" ry="5" width="65%" height="10" />
        <rect x="75%" y="335" rx="5" ry="5" width="10%" height="10" />
        <rect x="0" y="355" rx="5" ry="5" width="65%" height="10" />
        <rect x="75%" y="355" rx="5" ry="5" width="30%" height="10" />
        <rect x="0" y="375" rx="5" ry="5" width="65%" height="10" />
        <rect x="75%" y="375" rx="5" ry="5" width="30%" height="10" />
        <rect x="0" y="395" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="395" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="415" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="415" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="445" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="445" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="465" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="465" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="485" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="485" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="505" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="505" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="525" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="525" rx="5" ry="5" width="30%" height="8" />
        <rect x="75%" y="550" rx="5" ry="5" width="10%" height="10" />
        <circle cx="76.5%" cy="590" r="18" />
        <circle cx="80%" cy="590" r="18" />
        <circle cx="83.5%" cy="590" r="18" />
        <circle cx="87%" cy="590" r="18" />
        <circle cx="90.5%" cy="590" r="18" />
        <circle cx="94%" cy="590" r="18" />
      </ContentLoader>
    );
  }

  if (props.ratingreview) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height="auto"
        viewBox="0 0 550 90"
        backgroundColor="#F3F4F7"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="45" cy="50%" r="30" />
        <rect x="18%" y="10%" rx="5" ry="5" width="80%" height="7" />
        <rect x="18%" y="30%" rx="5" ry="5" width="80%" height="7" />
        <rect x="18%" y="50%" rx="5" ry="5" width="80%" height="7" />
        <rect x="18%" y="70%" rx="5" ry="5" width="80%" height="7" />
        <rect x="18%" y="90%" rx="5" ry="5" width="80%" height="7" />
      </ContentLoader>
    );
  }

  if (props.sidebarratingreview) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height="auto"
        viewBox="0 0 570 180"
        backgroundColor="#F3F4F7"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="10" rx="5" ry="5" width="100%" height="15" />
        <rect x="0" y="45" rx="5" ry="5" width="100%" height="15" />
        <rect x="0" y="80" rx="5" ry="5" width="100%" height="15" />
        <rect x="0" y="115" rx="5" ry="5" width="100%" height="15" />
        <rect x="0" y="150" rx="5" ry="5" width="100%" height="15" />
      </ContentLoader>
    );
  }

  return (
    <ContentLoader
      speed={1}
      viewBox="0 0 340 84"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="9" y="4" rx="0" ry="0" width="320" height="22" />
      <rect x="18" y="14" rx="0" ry="0" width="303" height="6" />
      <rect x="11" y="33" rx="0" ry="0" width="108" height="13" />
      <rect x="129" y="33" rx="0" ry="0" width="60" height="13" />
      <rect x="196" y="33" rx="0" ry="0" width="60" height="13" />
    </ContentLoader>
  );
};

const FadingLoaderCard1 = () => {
  return (
    <ContentLoader width={400} height={40} backgroundColor="#ababab" foregroundColor="#fafafa">
      <rect x="70" y="15" rx="5" ry="5" width="300" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="220" height="9" />
      <rect x="20" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

const FadingLoaderCard2 = () => {
  return (
    <ContentLoader width={400} height={40} backgroundColor="#bfbfbf" foregroundColor="#fafafa">
      <rect x="70" y="15" rx="5" ry="5" width="300" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="220" height="9" />
      <rect x="20" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

const FadingLoaderCard3 = () => {
  return (
    <ContentLoader width={400} height={40} backgroundColor="#dadada" foregroundColor="#fafafa">
      <rect x="70" y="15" rx="5" ry="5" width="300" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="220" height="9" />
      <rect x="20" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

const FadingLoaderCard4 = () => {
  return (
    <ContentLoader width={400} height={40} backgroundColor="#ececec" foregroundColor="#fafafa">
      <rect x="70" y="15" rx="5" ry="5" width="300" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="220" height="9" />
      <rect x="20" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

const FadingLoaderCard5 = () => {
  return (
    <ContentLoader width={400} height={40} backgroundColor="#f7f7f7" foregroundColor="#fafafa">
      <rect x="70" y="15" rx="5" ry="5" width="300" height="15" />
      <rect x="70" y="39" rx="5" ry="5" width="220" height="9" />
      <rect x="20" y="10" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  );
};

export default LoadingAnimation;
