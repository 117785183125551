import { gql } from '@apollo/client';

export const GQL_CREATE_OR_GET_CUSTOMER = gql`
  query getOrCreateCustomer($email: String, $name: String, $userId: ID) {
    getOrCreateCustomer(email: $email, name: $name, userId: $userId) {
      email
      name
      status
      message
    }
  }
`;
