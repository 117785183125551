import React, { FC } from "react";

const Spinner: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div
      {...props}
      className={`flex justify-center items-center ${props.className}`}
    >
      <div className="w-5 h-5 border-4 border-gray-500 border-t-transparent border-solid rounded-full animate-spin"></div>
    </div>
  );
};

export default Spinner;
