"use client";

import React, { useEffect } from "react";
import { Button } from "../ui/button";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { checkSubdomain, subdomainName } from "../../utils";

type BrokenLinkType = {
  children?: React.ReactNode;
  message?: string;
  secondMessage?: string;
  useRefreshButton?: boolean;
  isServerError?: boolean;
  isUseImageTama?: boolean;
  isNoSecondMessage?: boolean;
  isNoErrorCode?: boolean;
  isInvoiceMembership?: boolean;
  isMembershipActive?: boolean;
  buttonLinkHref?: string;
  isUnverifiedAccount?: boolean;
};

const BrokenLink: React.FC<BrokenLinkType> = ({
  children = null,
  message,
  secondMessage,
  useRefreshButton = false,
  isServerError = false,
  isUseImageTama = false,
  isNoSecondMessage = false,
  isNoErrorCode = false,
  isInvoiceMembership = false,
  isMembershipActive,
  buttonLinkHref,
  isUnverifiedAccount = false,
}) => {
  const { t } = useTranslation();
  const url = checkSubdomain(subdomainName());
  const errorCode = isServerError ? 500 : 404;
  const displayMessage = message || t(`alert.${errorCode}.title`);

  return (
    <div
      id="broken-link"
      className="flex justify-center flex-col items-center min-h-screen w-full"
    >
      {children}

      {isUnverifiedAccount && (
        <span className="text-center text-size font-bold text-blue-500 text-2xl mb-[5px]">
          {t("alert.unverifiedAccount")}
        </span>
      )}

      {isNoErrorCode ? (
        ""
      ) : (
        <span className="text-center text-size font-bold text-blue-500 text-3xl mb-3">
          {errorCode}
        </span>
      )}
      <h1 className="text-center text-size font-bold">
        {displayMessage}
        {isInvoiceMembership && (
          <a
            href={
              process.env.NODE_ENV === "development"
                ? `${process.env.NEXT_PUBLIC_BASE_URL}/pay-membership`
                : `${url}/pay-membership`
            }
            className="invoice-membership-link"
          >
            {t("word.here")}
          </a>
        )}
      </h1>
      <img
        src={
          isUseImageTama
            ? process.env.NEXT_PUBLIC_MAYAR_LOGO
            : process.env.NEXT_PUBLIC_MAYAR_KAREN_LOGO
        }
        alt={t(`alert.${errorCode}.title`)}
        className="height-logo-karen mb-[20px]"
      />
      {isNoSecondMessage && ""}
      {!isNoSecondMessage && (
        <p className="text-normal text-size text-center mb-[30px]">
          {secondMessage ? secondMessage : t(`alert.${errorCode}.message`)}
        </p>
      )}

      {isMembershipActive === false && (
        <a
          // color="primary"
          // className="text-center btn btn-success w-[50%] text-white"
          className="text-center w-[50%]"
          href={buttonLinkHref}
        >
          <Button
            variant="success"
            className="w-full text-center text-md font-semibold"
          >
            {t("form.paySubs")}
          </Button>
        </a>
      )}
      {isServerError || useRefreshButton ? (
        <Button
          variant="primary"
          className="text-center w-[50%] font-medium"
          onClick={() =>
            typeof window !== "undefined" && window.location.reload()
          }
        >
          {t("alert.500.button")}
        </Button>
      ) : (
        ""
      )}

      <Button
        variant="outline-primary"
        className="text-center w-[50%] my-[5px] font-[500] uppercase rounded-sm text-[12px]"
        onClick={() => typeof window !== "undefined" && window.history.back()}
      >
        {t("word.back")}
      </Button>
    </div>
  );
};

export default BrokenLink;
