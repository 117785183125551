import React from "react";
//import { useTranslation } from 'react-i18next';
// import Icon from '../icon';
import { Icon } from "@iconify/react";
import { cn } from "@/lib/utils";
const PoweredBy = ({ className }: { className?: string }) => {
  //const { t } = useTranslation();
  //const [modal, setModal] = useState(false);
  //const toggleModal = () => setModal(!modal);

  return (
    <>
      <div
        id="powered-by-mayar"
        className={cn("text-center mt-[20px]", className)}
      >
        <a
          href={"https://mayar.id/?utm_source=poweredby"}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <button
            color="link"
            className="hover:underline text-gray-900 text-[9px] relative inline-flex font-[600] py-[9px] px-[15px] uppercase items-center font-open-sans"
          >
            {/* <Icon name="lock" className="mr-[5px]" /> */}
            <Icon icon="octicon:lock-24" className="mr-[5px] mb-1 text-lg" />
            Powered by Mayar.ID
          </button>
        </a>
      </div>
    </>
  );
};

export default PoweredBy;
